/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


.jumbotron {
  padding: 1rem
}






.noVerticalPaddingBorder {
  border: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.hornochsen {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    font-size: min(1vw, 11px);
}
.rotate-180 {
  transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
}


.card-box {
    position: relative;
    max-width: 75pt;
    padding: 0;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.card-box:before{
  content: "";
  display: block;
  padding-top: 150%; /* Card ratio */
}
.card{
  position: absolute;
  top: 0.2rem;
  left: 0.2rem;
  bottom: 0.2rem;
  right: 0.2rem;
}


.card-value {
  text-align: center;
  font-size: min(3.65vw, 37px);
  font-weight: bold;
  
}

.card-no-slot {
  background-color: transparent;
  border-width: 0pt;
}
.card-empty-slot {
  background-color: transparent;
  border-width: 1pt;
 } 
.card-hornox-1 {
  background-color: white;
  color: blueviolet;
  border-width: 1pt;
}
.card-hornox-2 {
  background-color: blue;
  color: turquoise;
  border-width: 1pt;
}
.card-hornox-3 {
  background-color: rgb(70, 150, 70);
  color: yellow;
  border-width: 1pt;
}
.card-hornox-5 {
  background-color: rgb(175, 20, 20);
  color: rgb(100, 175, 100);
  border-width: 1pt;
}
.card-hornox-7 {
  background-color: rgb(255, 210, 0);
  color: red;
  border-width: 1pt;
}



.no-margin {
  margin:0
}

.full-height{
  height:100%;
}
.full-width {
  width: 100%;
}

.clickable {
  cursor: pointer;
}

/* hide parts of the website in case it is verry narrow */ 
@media only screen and (max-width: 767px) {
  .hide-sm {
    visibility: hidden;
  }
}
